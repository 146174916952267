import { createStore, createEvent } from 'effector';

export const setModelInformations = createEvent('set model informations');
export const setUsedModelValue = createEvent('set model value');
export const setPersonalInformations = createEvent('set personal informations');
export const setUsedOnChange = createEvent('set used on change');
export const setEntryValue = createEvent('set entry value');
export const setFinancingOptions = createEvent('set financing option');
export const setFinancingValue = createEvent('set financing value');
export const setUsedModelInformations = createEvent(
  'set used model informations',
);
export const setFinancingSimulationData = createEvent(
  'set financing simulation data',
);

const FinancigSimulator = (function () {
  let instance;

  return function (
    defaultValue = {
      unit: null,
      brand: null,
      productId: null,
      model: null,
      version: null,
      modelYear: null,
      fabricationYear: null,
      modelThumb: null,
      modelValue: null,
      username: null,
      phone: null,
      cpf: null,
      dateOfBirth: null,
      email: null,
      mailing: null,
      phoning: null,
      whatsapping: null,
      dataPermissions: null,
      usedOnChange: null,
      usedModelValue: null,
      entryValue: null,
      installmentsId: null,
      commissionCode: null,
      installments: null,
      installmentValue: null,
      financingValue: null,
      financedValue: null,
      financingMinEntryValue: null,
      financingEntryValueRecommended: null,
      usedModelBrand: null,
      usedModelName: null,
      usedModelYear: null,
      usedModelKM: null,
      usedModelFipeCode: null,
      simulationFuelYear: null,
      simulationModelId: null,
    },
  ) {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setModelInformations, (state, modelInformations) => ({
        ...state,
        unit: modelInformations.unit,
        brand: modelInformations.brand,
        productId: modelInformations.productId,
        model: modelInformations.model,
        version: modelInformations.version,
        modelYear: modelInformations.modelYear,
        fabricationYear: modelInformations.fabricationYear,
        modelThumb: modelInformations.modelThumb,
        modelValue: modelInformations.modelValue,
        simulationFuelYear: modelInformations.simulationFuelYear,
        simulationModelId: modelInformations.simulationModelId,
      }));

      instance.on(setUsedModelValue, (state, usedModelValue) => ({
        ...state,
        usedModelValue,
      }));

      instance.on(setPersonalInformations, (state, personalInformations) => ({
        ...state,
        username: personalInformations.name,
        phone: personalInformations.phone,
        cpf: personalInformations.cpf,
        email: personalInformations.email,
        dateOfBirth: personalInformations.dateOfBirth,
        mailing: personalInformations.mailing,
        phoning: personalInformations.phoning,
        whatsapping: personalInformations.whatsapping,
        dataPermissions: personalInformations.dataPermissions,
      }));

      instance.on(setUsedOnChange, (state, usedOnChange) => ({
        ...state,
        usedOnChange,
      }));

      instance.on(setEntryValue, (state, entryValue) => ({
        ...state,
        entryValue,
      }));

      instance.on(setFinancingOptions, (state, financingOption) => ({
        ...state,
        installmentsId: financingOption.installmentsId,
        commissionCode: financingOption.commissionCode,
        installments: financingOption.installments,
        installmentValue: financingOption.installmentValue,
      }));

      instance.on(setFinancingValue, (state, financingValue) => ({
        ...state,
        financingValue,
      }));

      instance.on(
        setFinancingSimulationData,
        (state, financingSimulationData) => ({
          ...state,
          financedValue: financingSimulationData.financedValue,
          financingMinEntryValue:
            financingSimulationData.financingMinEntryValue,
          financingEntryValueRecommended:
            financingSimulationData.financingEntryValueRecommended,
        }),
      );

      instance.on(setUsedModelInformations, (state, usedModelInformations) => ({
        ...state,
        usedModelBrand: usedModelInformations.usedModelBrand,
        usedModelName: usedModelInformations.usedModelName,
        usedModelYear: usedModelInformations.usedModelYear,
        usedModelKM: usedModelInformations.usedModelKM,
        usedModelFipeCode: usedModelInformations.usedModelFipeCode,
      }));
    }

    return instance;
  };
})();

export default FinancigSimulator;
