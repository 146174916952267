import { Component } from 'preact';
import PropTypes from 'prop-types';
import CustomCheck from '../CustomCheck';

import { setFinancingOptions } from '../../stores/FinancingSimulator';

export default class FinancingOption extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { chekedInInitialization } = this.props;

    if (chekedInInitialization) {
      this.handleInputChange();
    }
  }

  handleInputChange() {
    const { id, commissionCode, installments, installmentValue, onSelect } =
      this.props;

    const financingOption = {
      installmentsId: id,
      commissionCode,
      installments,
      installmentValue: this.maskedValue(installmentValue),
    };

    onSelect(financingOption);
    setFinancingOptions(financingOption);
  }

  maskedValue(number) {
    return number.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  render() {
    const { isChecked } = this.props;
    const { installments, installmentValue } = this.props;

    return (
      <div className="financing-option">
        <div className="financing-option__content-wrapper">
          <CustomCheck
            checkStyle="dark"
            name="financing-option"
            type="radio"
            isChecked={isChecked}
            onChangeCheckable={this.handleInputChange}
          />

          <div className="financing-option__content">
            <div className="financing-option__content-conditions">
              <h6>Condição</h6>
              <h6>
                <strong>
                  {installments}x de {this.maskedValue(installmentValue)}
                </strong>
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FinancingOption.defaultProps = {
  id: '',
  commissionCode: '',
  installments: '',
  installmentValue: '',
  isChecked: false,
  onSelect: () => {},
};
FinancingOption.propTypes = {
  id: PropTypes.number,
  commissionCode: PropTypes.string,
  installments: PropTypes.number,
  installmentValue: PropTypes.number,
  isChecked: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};
