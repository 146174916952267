import '../showroom';
import './components';
import './stores';
import { unitsSelectSetupOnLoad } from '../auto/unitsSelect';
import { makeAllDdmOffersListSyncService } from '../auto/services/DdmServiceV2/makeAllDdmOffersListSyncService';
import { DDMAdapter } from '../auto/services/ddm_service/adapters/ddmAdapter';
import { setDropdownInteration } from '../auto/utils/components';
import { addExceptionModelsToChannelData } from '../auto/services/ddm_service/utils';
import { renderFeaturesDisplay } from '../auto/utils/featuresDisplay';

window.ddmOffersListSyncService = makeAllDdmOffersListSyncService();
window.DDMAdapter = DDMAdapter;
window.helpers = {
  ...window.helpers,
  addExceptionModelsToChannelData,
};

window.components = {
  setDropdownInteration,
};

window.renderFeaturesDisplay = renderFeaturesDisplay;

$(document).ready(function () {
  unitsSelectSetupOnLoad();
});
