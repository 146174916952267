import FinancingSimulator from '../auto/stores/FinancingSimulator';
import VersionComparison from '../auto/stores/VersionComparison';
import DDMOffers from '../auto/stores/DDMOffers';
import DDMOffersFilter from '../auto/stores/DDMOffersFilter';

(function () {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  let store = {
    FinancingSimulator,
    VersionComparison,
    DDMOffers,
    DDMOffersFilter,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach((c) => (window.store[c] = store[c]()));
})();
