import { Component } from 'preact';
import PropTypes from 'prop-types';
import IMask from 'imask';

import CustomSelect from '../../CustomSelect';
import UsedModelBoxInfo from '../UsedModelBoxInfo';
import { priceMask, kmMask } from '../../../utils/masks';
import fipeServiceApi from '../../../utils/fipeServiceApi';
import getUTM from '%/utils/utm';

import {
	setUsedModelValue,
  setUsedModelInformations
} from '../../../stores/FinancingSimulator';

export default class UsedModelEvaluation extends Component {
	constructor(props){
		super(props);

    this.state = {
      brands: [],
      models: [],
      modelYears: [],
      usedModelBrand: '',
      usedModelName: '',
      usedModelYear: '',
      usedModelAveragePrice: '',
      usedModelKM: '',
      usedModelFipeCode: '',
      usedModelSaleValue: '',
      usedModelBrandIsValid: true,
      usedModelNameIsValid: true,
      usedModelYearIsValid: true,
      usedModelKMIsValid: true,
      usedModelSaleValueIsValid: true
    };

    this.handleUsedModelBrandChange = this.handleUsedModelBrandChange.bind(this);
    this.handleUsedModelNameChange = this.handleUsedModelNameChange.bind(this);
    this.handleUsedModelYearChange = this.handleUsedModelYearChange.bind(this);
    this.handleUsedModelKMChange = this.handleUsedModelKMChange.bind(this);
    this.handleUsedModelSaleValue = this.handleUsedModelSaleValue.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.saveUsedModelInformation = this.saveUsedModelInformation.bind(this);

    window.store.FinancingSimulator.watch(setUsedModelValue, (store) => {
      this.setState({
        usedModelSaleValue: store.usedModelValue
      });
    });
	}

  componentDidMount() {
    fipeServiceApi.getBrands().then(brands => this.setState({ brands }));

    IMask(this.usedModelSaleValueInput, priceMask);
    IMask(this.usedModelKMInput, { mask: kmMask });
  }

  handleUsedModelBrandChange(e) {
    const { value: brandId } = e.target;

    if (!brandId) return;

    fipeServiceApi.getModels(brandId).then((models) => {
      this.setState({
        models,
        usedModelBrand: brandId,
        usedModelBrandIsValid: true,
        usedModelName: '',
        usedModelYear: '',
        usedModelAveragePrice: ''
      });
    });
  }

  handleUsedModelNameChange(e) {
    const { value: modelId } = e.target;

    if (!modelId) return;

    fipeServiceApi.getModelYears(
      this.state.usedModelBrand,
      modelId
    ).then((modelYears) => {
      this.setState({
        modelYears,
        usedModelName: modelId,
        usedModelNameIsValid: true,
        usedModelYear: '',
        usedModelAveragePrice: ''
      });
    });
  }

  handleUsedModelYearChange(e) {
    const { value: modelYear } = e.target;

    if (!modelYear) return;

    fipeServiceApi.getPrice(
      this.state.usedModelBrand,
      this.state.usedModelName,
      modelYear
    ).then((data) => {
      this.setState({
        usedModelYear: modelYear,
        usedModelYearIsValid: true,
        usedModelAveragePrice: data.price,
        usedModelFipeCode: data.fipeCode
      });
    });
  }

  handleUsedModelKMChange(e) {
    const { value } = e.target;

    this.setState({
      usedModelKM: value,
      usedModelKMIsValid: true
    });
  }

  handleUsedModelSaleValue(e) {
    const { value } = e.target;

	  const valueFormatted = value.replace("R$ ", "");

    this.setState({
      usedModelSaleValue: valueFormatted,
      usedModelSaleValueIsValid: true
    });

    setUsedModelValue(valueFormatted);
  }

  getUsedModelBrandLabel() {
    const found = this.state.brands.find(brand => brand.value == this.state.usedModelBrand);

    return (found && found.label) || '';
  }

  getUsedModelNameLabel() {
    const found = this.state.models.find(model => model.value == this.state.usedModelName);

    return (found && found.label) || '';
  }

  getUsedModelYearLabel() {
    const found = this.state.modelYears.find(modelYear => modelYear.value == this.state.usedModelYear);

    return (found && found.label) || '';
  }

  saveUsedModelInformation() {
    const { usedModelKM, usedModelFipeCode } = this.state;

    const usedModelInformation = {
      usedModelBrand: this.getUsedModelBrandLabel(),
      usedModelName: this.getUsedModelNameLabel(),
      usedModelYear: this.getUsedModelYearLabel(),
      usedModelKM: usedModelKM,
      usedModelFipeCode: usedModelFipeCode
    }

    setUsedModelInformations(usedModelInformation);
  }

  handleNextStep(e) {
    e.preventDefault();

    this.saveUsedModelInformation();

    const formElements = [
      {
        element: this.usedModelBrandInput,
        stateKey: 'usedModelBrandIsValid'
      },
      {
        element: this.usedModelNameInput,
        stateKey: 'usedModelNameIsValid'
      },
      {
        element: this.usedModelYearInput,
        stateKey: 'usedModelYearIsValid'
      },
      {
        element: this.usedModelKMInput,
        stateKey: 'usedModelKMIsValid'
      },
      {
        element: this.usedModelSaleValueInput,
        stateKey: 'usedModelSaleValueIsValid'
      }
    ];

    const formIsValid = formElements.every(({ element, stateKey }) => {
      const value = String(element.selectElement ? element.selectElement.value : element.value).trim();

      if (value == '' || value == 'R$') {
        this.setState({ [stateKey]: false });

        return false;
      }
      else {
        this.setState({ [stateKey]: true });

        return true;
      }
    });

    if (!formIsValid) {
      e.stopImmediatePropagation();
      return;
    }

    this.sendConversion();
  }

  sendConversion() {
    const params = this.getConversionParams();

    return serviceConversion
      .convert(params)
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  getConversionParams() {
    const {
      model,
      version,
      unit,
      modelValue,
      username: name,
      phone,
      cpf,
      email,
      mailing,
      phoning,
      whatsapping,
      usedOnChange,
      usedModelBrand,
      usedModelName,
      usedModelYear,
      productId
    } = window.store.FinancingSimulator.getState();

    return {
      bait: '-7',
      paymentMethod: 'Quero financiar',
      SubOrigem__c: 'DDM Financiamento',
      category: this.props.category,
      utmz: getUTM(),
      brand: this.props.brand,
      channel: this.props.channel,
      unit: unit,
      product_id: productId,
      product: model,
      version: version,
      modelValue: modelValue,
      cpf: cpf,
      email: email,
      name: name,
      mailing: mailing,
      phone: phone,
      phoning: phoning,
      whatsapping: whatsapping,
      usedOnChange: usedOnChange,
      usedModelBrand: usedModelBrand,
      usedModelName: usedModelName,
      usedModelYear: usedModelYear,
      usedModelAveragePrice: this.state.usedModelAveragePrice,
      usedModelKM: this.state.usedModelKM,
      usedModelFipeCode: this.state.usedModelFipeCode,
      usedModelSaleValue: this.state.usedModelSaleValue,
    };
  }

  render() {
    const { formId } = this.props;

    const {
      brands,
      models,
      modelYears,
      usedModelBrand,
      usedModelName,
      usedModelYear,
      usedModelAveragePrice,
      usedModelKM,
      usedModelSaleValue,
      usedModelBrandIsValid,
      usedModelNameIsValid,
      usedModelYearIsValid,
      usedModelKMIsValid,
      usedModelSaleValueIsValid
    } = this.state;

		return (
			<div className="used-model-evaluation">
				<h2>Avaliação de Seminovo</h2>
        <h6>
					Preencha os campos abaixo com seus dados para que possamos entrar em contato e prosseguir com a negociação.
        </h6>

        <hr />

        <form
          id={formId}
          ref={form => (this.form = form)}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-3">
              <div className="form-group">
                <label htmlFor="used-model-bran">Selecione a marca do seu Seminovo</label>
                <CustomSelect
                  handleSelectChange={this.handleUsedModelBrandChange}
                  name="used-model-brand"
                  options={brands}
                  value={usedModelBrand}
                  placeholderValue="Escolha uma marca"
                  searchEnabled={true}
                  ref={usedModelBrandInput => (this.usedModelBrandInput = usedModelBrandInput)}
                />

                {!usedModelBrandIsValid && (
                  <div id="invalid-used-model-brand" class="invalid-feedback is-invalid-used-model-brand">
                    <div class="error-message" id="bouncer-error_used-model-brand">Por favor, selecione esse campo</div>
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="used-model-name">Selecione o modelo do seu Seminovo</label>
                <CustomSelect
                  handleSelectChange={this.handleUsedModelNameChange}
                  name="used-model-name"
                  options={models}
                  value={usedModelName}
                  placeholderValue="Escolha o modelo"
                  searchEnabled={true}
                  ref={usedModelNameInput => (this.usedModelNameInput = usedModelNameInput)}
                />

                {!usedModelNameIsValid && (
                  <div id="invalid-used-model-name" class="invalid-feedback is-invalid-used-model-name">
                    <div class="error-message" id="bouncer-error_used-model-name">Por favor, selecione esse campo</div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                  <div className="form-group">
                    <label htmlFor="used-model-year">Selecione o ano do modelo</label>
                    <CustomSelect
                      handleSelectChange={this.handleUsedModelYearChange}
                      name="used-model-year"
                      options={modelYears}
                      value={usedModelYear}
                      placeholderValue="Ano"
                      searchEnabled={true}
                      ref={usedModelYearInput => (this.usedModelYearInput = usedModelYearInput)}
                    />

                    {!usedModelYearIsValid && (
                      <div id="invalid-used-model-year" class="invalid-feedback is-invalid-used-model-year">
                        <div class="error-message" id="bouncer-error_used-model-year">Por favor, selecione esse campo</div>
                      </div>
                    )}
                  </div>
                </div>

                {window.screen.width > 768 && (
                  <div className="col-lg-6 col-md-6 col-sm-12 align-self-center d-none d-md-block">
                    <div className="form-group">

                      <label htmlFor="used-model-km">Informe a quilometragem</label>
                      <input
                        onChange={this.handleUsedModelKMChange}
                        value={usedModelKM}
                        type="text"
                        className="form-control"
                        name="used-model-km"
                        required
                        placeholder="KM"
                        data-bouncer-target="#invalid-used-model-km"
                        ref={usedModelKMInput => (this.usedModelKMInput = usedModelKMInput)}
                      />

                      {!usedModelKMIsValid && (
                        <div id="invalid-used-model-km" class="invalid-feedback is-invalid-used-model-km">
                          <div class="error-message" id="bouncer-error_used-model-km">Por favor, informe esse campo</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-3">
              <UsedModelBoxInfo
                brand={this.getUsedModelBrandLabel()}
                model={this.getUsedModelNameLabel()}
                yearModel={this.getUsedModelYearLabel()}
                value={usedModelAveragePrice}
              />

              {window.screen.width <= 768 && (
                <div className="form-group d-md-none">
                  <label htmlFor="used-model-km">Informe a quilometragem</label>
                  <input
                    onChange={this.handleUsedModelKMChange}
                    value={usedModelKM}
                    type="text"
                    className="form-control"
                    name="used-model-km"
                    required
                    placeholder="KM"
                    data-bouncer-target="#invalid-used-model-km"
                    ref={usedModelKMInput => (this.usedModelKMInput = usedModelKMInput)}
                  />

                  {!usedModelKMIsValid && (
                    <div id="invalid-used-model-km" class="invalid-feedback is-invalid-used-model-km">
                      <div class="error-message" id="bouncer-error_used-model-km">Por favor, informe esse campo</div>
                    </div>
                  )}
                </div>
              )}

              <div className="form-group">
                <label htmlFor="used-model-sale-value">Por qual valor você deseja vender seu seminovo?</label>
                <input
                  onChange={this.handleUsedModelSaleValue}
                  value={usedModelSaleValue ? `R$ ${usedModelSaleValue}` : usedModelSaleValue}
                  type="text"
                  className="form-control"
                  name="used-model-sale-value"
                  required
                  placeholder="Ex: R$ 70.000"
                  data-bouncer-target="#invalid-used-model-sale-value"
                  ref={usedModelSaleValueInput => (this.usedModelSaleValueInput = usedModelSaleValueInput)}
                />

                {!usedModelSaleValueIsValid && (
                  <div id="invalid-used-model-sale-value" class="invalid-feedback is-invalid-used-model-sale-value">
                    <div class="error-message" id="bouncer-error_used-model-sale-value">Por favor, informe esse campo</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>

				<div class="btns-group">
					<button class="btn btn-prev btn-prev--outline font-weight-bold">Anterior</button>
					<button
            class="btn btn-next button--primary font-weight-bold"
            onClick={this.handleNextStep}
          >
            Próximo
          </button>
				</div>
			</div>
		);
	}
}

UsedModelEvaluation.defaultProps = {
  formId: ''
}

UsedModelEvaluation.propTypes = {
  formId: PropTypes.string
}
