import { h, Component } from 'preact';
import PropTypes from 'prop-types';

export default class FinancingCTABox extends Component {
  constructor(props){
    super(props);

    this.state = {
      link: ''
    }

    window.store.vehicleVersionDetails.watch(({ activeVersion }) => {
      if (activeVersion) {
        activeVersion && this.setState({ versionSlug: activeVersion.slug })
      }
    })
  }

  render(){
    const { link } = this.props;
    const { versionSlug } = this.state;

    return (
      <div class="financing-cta-box">
        <div class="cta-box cta-box--vertical cta-box--whatsapp">
          <div class="cta-box__phrase">
            <span>
              Simule um financiamento sem compromisso!
            </span>
          </div>
          <div 
            class="cta-box__button-container"
          >
            <a 
              href={`${link}/${versionSlug}`}
              id="new-vehicles-showcase_cta-box-button"
              class="btn button button--large"
            >
              SIMULAR FINANCIAMENTO
            </a>
          </div>
        </div>
      </div>
    );
  }
}