import { Component } from 'preact';

export default class UsedModelBoxInfo extends Component {
  constructor(props) {
		super(props);
	}

  render() {
    const {
      brand,
      model,
      yearModel,
      value
    } = this.props;

    return (
      <div className="used-model-box-info p-3">
        <h5>
          {model ? (
            <span>
              <strong>{brand} {model}</strong>
              {yearModel && <strong> - {yearModel}</strong>}
            </span>
          ) : (
            <span>
              <strong>ESCOLHA SEU MODELO</strong>
            </span>
          )}
        </h5>
        <hr />
        <h6>
          Preço médio: {value ? <strong>{value}</strong> : <strong> - </strong>}
        </h6>
        <hr />
        <h6>* Preço Médio extraído da tabela FIPE.</h6>
      </div>
    );
  }
}
