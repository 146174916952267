import { h, Component } from 'preact';

export default class ItemVehicle extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    const {
      modelName,
      version,
      unit,
      thumb,
      thumbAlt,
      textPrice,
      price
    } = this.props;

    return(
      <div className="financing-simulador__card">
        <div className="row">
          <div className="data-model col-sm-12 col-md-5 d-flex flex-column justify-content-between">
            <div className="financing-simulador__card__top">
              <h3 className="financing-simulador__card__title">{modelName}</h3>
              <h6 className="financing-simulador__card__subtitle">
                {version}
              </h6>
              <h6 className="financing-simulador__card__subtitle--gray">
                {unit && (
                  <span>
                      <i className="icon icon-map"></i>
                      {unit}
                  </span>
                )}
              </h6>
            </div>
            <div className="financing-simulador__card__content">
              <div className="financing-simulador__card__price d-none d-md-block">
                <h4>{textPrice}: </h4>
                <h3>R$ {price}</h3>
              </div>
            </div>
          </div>
          <div className="img-model col-sm-12 col-md-7">
            <img 
              className="financing-simulador__card__img img-fluid"
              src={thumb} 
              alt={thumbAlt}
            />
            <div className="financing-simulador__card__price d-md-none">
              <h4>{textPrice}: <strong>R$ {price}</strong></h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}