import { getCookie } from './utils/cookies';

const setClickEvents = () => {
  $('.unit-choices__selected').on('click', (e) => {
    const choicesList = document.querySelector('.unit-choices__list');
    const selected = e.target;
    choicesList.classList.toggle('show');
    selected.classList.toggle('expanded');
  });

  $('.unit-choices__list-item-value').on('click', (e) => {
    e.preventDefault();

    const unitsData = JSON.parse(document.querySelector(
      '.units-modal .unit-choices'
    ).dataset.units);

    const { id, url } = e.target.dataset;
    const chosenUnit = unitsData.find(unit => unit.id === Number(id));

    document.cookie = `current_unit=${chosenUnit.id};path=/`;

    if (window.location.href.startsWith(e.target.href)) {
      const formattedPathname = unitsData.reduce((acc, unit) => {
        return unit.url ? acc.replace(unit.url, '') : acc;
      }, window.location.pathname);
      window.location.href = `${url}${formattedPathname}?unidade=${chosenUnit.slug}`;
    } else {
      window.location.href = `${url}?unidade=${chosenUnit.slug}`;
    }
  });
};

export const unitsSelectSetupOnLoad = () => {
  setClickEvents();

  const $el = document.querySelector(
    '.header__units-container .list--header-units'
  );

  if(!$el) return;

  // captura os dados de todas as unidades agrupadas
  const unitsData = JSON.parse($el.dataset.units);

  // verifica se já existe unidade definida e se ela faz parte das unidades agrupadas
  const currentUnit = getCookie('current_unit');
  if (currentUnit && unitsData.some(u => u.id === currentUnit)) return;

  // caso o canal só possua uma unidade, define ela como padrão e não exibe o modal de select
  if (unitsData.length === 1) {
    const labelsWithSelectedUnit = Array.from(document.querySelectorAll(
      '.header-fca .header__units-button-toggler'
    ));
    labelsWithSelectedUnit.forEach(label => label.innerHTML = `<i class="icon icon-map"></i> ${unitsData[0].fullname} <i class="icon icon-arrow-d icon-dropdown"></i>`);

    document.cookie = `current_unit=${unitsData[0].id};path=/`;
    return;
  }

  // verifica se o modal de select está presente no html
  const modal = document.querySelector(
    '.units-modal'
  );

  if(!modal) return;

  // se não houver unidade definida nos query params, exibe o modal
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (!params.unidade) {
    modal.classList.add('show');
    return;
  } else {
    modal.classList.remove('show');
    return;
  }
};
