/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Component } from 'preact';

import PropTypes from 'prop-types';

import handleInputChange from '%/utils/handleInputChange';
import validationFactory from '%/utils/validation';
import { phoneMasks } from '%/utils/masks';
import getUTM from '%/utils/utm';
import CustomCheck from '../../CustomCheck';
import InputCpf from '../../InputCpf';

import {
  setPersonalInformations,
  setUsedOnChange,
  setUsedModelValue,
  setUsedModelInformations,
} from '../../../stores/FinancingSimulator';

export default class PersonalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      mailing: false,
      phoning: false,
      whatsapping: false,
      dataPermissions: false,
      'veiculo-na-troca': 'nao',
      contactOptions: '',
      cpf: '',
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getConversionParams = this.getConversionParams.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleUsedOnChange = this.handleUsedOnChange.bind(this);
    this.maskFactory = this.maskFactory.bind(this);
  }

  componentDidMount() {
    this.validator = validationFactory(`#${this.props.formId}`);
    this.setFieldMasks();
  }

  handleUsedOnChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    setUsedModelValue('');
    setUsedModelInformations({
      usedModelBrand: '',
      usedModelName: '',
      usedModelYear: '',
      usedModelKM: '',
      usedModelFipeCode: '',
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.savePersonalInformation();

    const validationResult = this.validator.validateAll(this.form);
    const formIsValid = validationResult.length === 0;

    if (!formIsValid) {
      e.stopImmediatePropagation();
      return;
    }

    const params = this.getConversionParams();

    window.serviceConversion
      .convert(params)
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  setFieldMasks() {
    const fields = [
      {
        field: this.phoneInput,
        state: 'phone',
        masks: phoneMasks,
      },
    ];

    fields.forEach(field => {
      this.maskFactory(field);
    });
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getConversionParams() {
    const { model, version, unit, modelValue, productId } =
      window.store.FinancingSimulator.getState();

    return {
      bait: '-7',
      paymentMethod: 'Quero financiar',
      SubOrigem__c: 'DDM Financiamento',
      category: this.props.category,
      utmz: getUTM(),
      brand: this.props.brand,
      channel: this.props.channel,
      unit,
      product_id: productId,
      product: model,
      version,
      modelValue,
      cpf: this.state.cpf,
      email: this.state.email,
      name: this.state.name,
      mailing: this.state.mailing,
      phone: this.state.phone,
      dateOfBirth: this.state.dateOfBirth,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
      usedOnChange: this.state['veiculo-na-troca'],
      dataPermissions: this.state.dataPermissions,
    };
  }

  maskFactory({ field, state, masks }) {
    if (!field) {
      return;
    }

    const fieldMask = window.IMask(field, {
      mask: [...masks],
    });

    fieldMask.on('accept', () => this.setState({ [state]: fieldMask.value }));
  }

  savePersonalInformation() {
    const {
      name,
      phone,
      cpf,
      email,
      dateOfBirth,
      mailing,
      phoning,
      whatsapping,
      dataPermissions,
    } = this.state;

    const personalInformations = {
      name,
      phone,
      cpf,
      email,
      dateOfBirth,
      mailing,
      phoning,
      whatsapping,
      dataPermissions,
    };

    setPersonalInformations(personalInformations);
    setUsedOnChange(this.state['veiculo-na-troca']);
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  render() {
    return (
      <div className="personal-info">
        <form
          id={this.props.formId}
          className=""
          onSubmit={this.handleSubmit}
          ref={form => (this.form = form)}
        >
          <h2>{this.props.titleForm}</h2>
          <h6>{this.props.subtitleForm}</h6>
          <hr />

          <div className="personal-info__form">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-1">
                <div className="form-group">
                  <label className="bold-label">Nome</label>
                  <input
                    type="text"
                    maxLength={80}
                    placeholder="Ex.: Ayrton Senna"
                    name="name"
                    className="form-control"
                    onChange={this.handleInputChange}
                    value={this.state.name}
                    required
                    data-bouncer-target="#invalid-name"
                  />
                  <div id="invalid-name" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-1">
                <div className="form-group">
                  <label className="bold-label">Telefone</label>
                  <input
                    type="phone"
                    placeholder="Ex.: (99) 99999-9999"
                    name="phone"
                    className="form-control"
                    ref={phoneInput => (this.phoneInput = phoneInput)}
                    value={this.state.phone}
                    required
                    onChange={this.handleInputChange}
                    data-bouncer-target="#invalid-phone"
                  />
                  <div id="invalid-phone" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 align-self-center mb-1">
                <div className="form-group">
                  {this.props.showCpf && (
                    <InputCpf
                      label="CPF"
                      handleChange={this.handleInputChange}
                      value={this.state.cpf}
                      placeholder="000.000.000-00"
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 align-self-center mb-1">
                <div className="form-group">
                  <label className="bold-label">E-mail</label>
                  <input
                    type="email"
                    maxLength={80}
                    placeholder="Ex.: ayrtonsenna@email.com"
                    name="email"
                    className="form-control"
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    required
                    data-bouncer-target="#invalid-email"
                  />
                  <div id="invalid-email" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 align-self-center mb-1">
                <div className="form-group">
                  <label className="bold-label">Data de nascimento</label>
                  <input
                    onChange={this.handleInputChange}
                    value={this.state.dateOfBirth}
                    type="date"
                    className="form-control"
                    name="dateOfBirth"
                    max="9999-12-31"
                    required
                    data-bouncer-target="#invalid-date-of-birth"
                  />
                  <div
                    id="invalid-date-of-birth"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                <div className="form-group">
                  <fieldset>
                    <label className="bold-label">
                      Você pretende usar seu veículo seminovo na negociação?
                    </label>
                    <div className="form-check form-check-inline">
                      <CustomCheck
                        checkStyle="dark"
                        name="veiculo-na-troca"
                        value="sim"
                        isChecked={this.state['veiculo-na-troca'] === 'sim'}
                        onChangeCheckable={this.handleUsedOnChange}
                        type="radio"
                      >
                        Sim
                      </CustomCheck>
                    </div>

                    <div className="form-check form-check-inline">
                      <CustomCheck
                        checkStyle="dark"
                        name="veiculo-na-troca"
                        value="nao"
                        isChecked={this.state['veiculo-na-troca'] === 'nao'}
                        onChangeCheckable={this.handleUsedOnChange}
                        type="radio"
                      >
                        Não
                      </CustomCheck>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                <div className="form-group">
                  <fieldset>
                    <label className="bold-label">
                      Quero receber contato por:
                    </label>

                    <div className="form-check form-check-inline">
                      <CustomCheck
                        checkStyle="dark"
                        name="mailing"
                        value="true"
                        type="checkbox"
                        isChecked={this.state.mailing}
                        onChangeCheckable={this.handleContactOptionsChange}
                      >
                        E-mail
                      </CustomCheck>
                    </div>

                    <div className="form-check form-check-inline">
                      <CustomCheck
                        checkStyle="dark"
                        name="whatsapping"
                        value="true"
                        type="checkbox"
                        isChecked={this.state.whatsapping}
                        onChangeCheckable={this.handleContactOptionsChange}
                      >
                        Whatsapp
                      </CustomCheck>
                    </div>

                    <div className="form-check form-check-inline">
                      <CustomCheck
                        checkStyle="dark"
                        name="phoning"
                        value="true"
                        type="checkbox"
                        isChecked={this.state.phoning}
                        onChangeCheckable={this.handleContactOptionsChange}
                      >
                        Telefone
                      </CustomCheck>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control d-none"
                        name="contact-options"
                        required
                        placeholder="Opções de contato"
                        data-bouncer-target="#invalid-contact-options"
                        value={this.state.contactOptions}
                        checked={this.state.contactOptions}
                      />
                      <div
                        id="invalid-contact-options"
                        className="invalid-feedback"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <hr className="line-separator" />

            <div className="conversion-step__data-permissions-field">
              <fieldset>
                <div className="form-check form-check-inline  d-flex">
                  <CustomCheck
                    checkStyle="dark"
                    name="dataPermissions"
                    value="true"
                    type="checkbox"
                    isChecked={this.state.dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                  />
                  <span>
                    Aceito compartilhar meus dados com a {this.props.channel}.
                    Estamos comprometidos em resguardar suas informações.
                    Conheça nossa{' '}
                    <a
                      target="_blank"
                      href={this.props.linkPrivacyPolicy}
                      rel="noreferrer"
                    >
                      Política de privacidade
                    </a>
                    .
                  </span>
                </div>
              </fieldset>
            </div>
          </div>

          <hr className="line-separator d-md-none mt-3" />

          <div className="btns-group my-4">
            <button
              type="button"
              className="btn btn-prev btn-prev--outline font-weight-bold"
            >
              Anterior
            </button>
            <button
              type="button"
              className="btn btn-next button--primary font-weight-bold"
              onClick={this.handleSubmit}
              data-jump-to-financing-simulation={this.state['veiculo-na-troca']}
            >
              Próximo
            </button>
          </div>

          <hr className="line-separator" />

          <div className="conversion-step__legal-text">
            <p>
              A {this.props.channel} é correspondente no país e atua no
              encaminhamento de propostas de financiamento de veículos para o
              Itaú.
              <br />
              Para mais informações sobre financiamento, entre em contato com a
              Central de Atendimento Itaú: Capitais 4002 0234 | Demais
              localidades 0800 729 0234
              <br />
              Segunda a sexta, das 8h às 20h | Sábados, das 9h às 15h |
              Domingos, 24h (apenas eletrônico)
              <br />
              Ouvidoria: 0800 570 0011. Se não ficar satisfeito com a solução
              apresentada, entre em contato conosco. Segunda a sexta, das 9h às
              18h (exceto feriados).
            </p>
          </div>
        </form>
      </div>
    );
  }
}

PersonalInformation.defaultProps = {
  titleForm: 'Informações para contato',
  channel: '',
  formId: '',
  category: '',
  brand: '',
  subtitleForm:
    'Preencha os campos abaixo com seus dados para que possamos entrar em contato e prosseguir com a negociação.',
  showCpf: true,
  linkPrivacyPolicy: '',
};

PersonalInformation.propTypes = {
  titleForm: PropTypes.string,
  channel: PropTypes.string,
  formId: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  subtitleForm: PropTypes.string,
  showCpf: PropTypes.bool,
  linkPrivacyPolicy: PropTypes.string,
};
