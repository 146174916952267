import { Component } from 'preact';

import ItemVehicle from '../ItemVehicle';
import {
  setPersonalInformations,
  setUsedOnChange,
  setModelInformations,
  setUsedModelValue,
  setEntryValue,
  setFinancingOptions,
  setFinancingValue,
  setUsedModelInformations,
  setFinancingSimulationData,
} from '../../../stores/FinancingSimulator';

export default class FinancingSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      email: '',
      phone: '',
      phoning: false,
      mailing: false,
      whatsapping: false,
      model: '',
      version: '',
      unit: '',
      modelThumb: '',
      usedModelBrand: '',
      usedModelName: '',
      usedModelYear: '',
      usedModelValue: '',
      usedOnChange: '',
      entryValue: '',
      installments: '',
      installmentValue: '',
      financingValue: 0.0,
      financingMinEntryValue: 0.0,
      financedValue: 0.0,
    };

    window.store.FinancingSimulator.watch(setModelInformations, store => {
      this.setState({
        model: store.model,
        version: store.version,
        unit: store.unit,
        modelThumb: store.modelThumb,
        modelValue: store.modelValue,
      });
    });

    window.store.FinancingSimulator.watch(setUsedModelValue, store => {
      this.setState({
        usedModelValue: store.usedModelValue,
      });
    });

    window.store.FinancingSimulator.watch(setUsedModelInformations, store => {
      this.setState({
        usedModelBrand: store.usedModelBrand,
        usedModelName: store.usedModelName,
        usedModelYear: store.usedModelYear,
      });
    });

    window.store.FinancingSimulator.watch(setUsedOnChange, store => {
      this.setState({
        usedOnChange: store.usedOnChange,
      });
    });

    window.store.FinancingSimulator.watch(setPersonalInformations, store => {
      this.setState({
        username: store.username,
        email: store.email,
        phone: store.phone,
        cpf: store.cpf,
        mailing: store.mailing,
        phoning: store.phoning,
        whatsapping: store.whatsapping,
      });
    });

    window.store.FinancingSimulator.watch(setEntryValue, store => {
      this.setState({
        entryValue: store.entryValue,
      });
    });

    window.store.FinancingSimulator.watch(setFinancingOptions, store => {
      this.setState({
        installments: store.installments,
        installmentValue: store.installmentValue,
      });
    });

    window.store.FinancingSimulator.watch(setFinancingValue, store => {
      this.setState({
        financingValue: store.financingValue,
      });
    });

    window.store.FinancingSimulator.watch(setFinancingSimulationData, store => {
      this.setState({
        financingMinEntryValue: store.financingMinEntryValue,
        financedValue: store.financedValue,
      });
    });
  }

  maskedValue(number) {
    if (!number) return '';

    return number.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  render() {
    const {
      username,
      email,
      mailing,
      phoning,
      whatsapping,
      entryValue,
      modelValue,
      usedModelBrand,
      usedModelName,
      usedModelYear,
      usedModelValue,
      model,
      version,
      modelThumb,
      unit,
      installments,
      installmentValue,
      financingValue,
      financingMinEntryValue,
      financedValue,
    } = this.state;

    return (
      <div className="financing-summary">
        <h2>Resumo</h2>
        <h6>
          Confira os dados da sua simulação. Em breve um de nossos consultores
          entrará em contato para dar prosseguimento na negociação.
        </h6>
        <hr />
        <div className="financing-summary-info">
          <div className="row">
            <div className="personal-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <h3>Meus dados</h3>
              <div className="summary__info-item">
                <h4 className="bold-label">Nome: </h4>
                <p>{username}</p>
              </div>
              <div className="summary__info-item">
                <h4 className="bold-label">Email: </h4>
                <p>{email}</p>
              </div>
              <div className="summary__info-item">
                <h4 className="bold-label">Preferência de contato: </h4>
                <p>
                  <span className="contact-option">
                    {mailing == true && 'E-mail'}
                  </span>
                  <span className="contact-option">
                    {phoning == true && ' Telefone'}
                  </span>
                  <span className="contact-option">
                    {whatsapping == true && ' Whatsapp'}
                  </span>
                </p>
              </div>
              <hr />
            </div>
            <div className="new-vehicle-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Veículo</h3>
                <ItemVehicle
                  modelName={model}
                  version={version}
                  unit={unit}
                  thumb={modelThumb}
                  thumbAlt={model}
                  textPrice="Valor do modelo"
                  price={modelValue}
                />
              </div>
              <hr />
            </div>
            <div className="entry-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Simulação Base</h3>
                <div className="summary__info-item">
                  <h4 className="bold-label">Valor da entrada: </h4>
                  <p>R$ {entryValue}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Valor a ser financiado: </h4>
                  <p>R$ {financingValue}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Seminovo para negociação: </h4>
                  <p>
                    {usedModelBrand
                      ? `${usedModelBrand} ${usedModelName} - ${usedModelYear}`
                      : 'Nenhum seminovo informado'}
                  </p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">
                    Valor do seminovo para negociação:{' '}
                  </h4>
                  <p>R$ {usedModelValue || '0,00'}</p>
                </div>
              </div>
              <hr />
            </div>
            <div className="financing-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Proposta de financiamento</h3>
                <div className="summary__info-item">
                  <h4 className="bold-label">Valor a ser financiado: </h4>
                  <p>R$ {this.maskedValue(financedValue)}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Entrada mínima: </h4>
                  <p>R$ {this.maskedValue(financingMinEntryValue) || '0,00'}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Valor da parcela: </h4>
                  <p>R$ {this.maskedValue(installmentValue)} </p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Quantidade de parcelas: </h4>
                  <p>{installments} parcelas </p>
                </div>
              </div>
              <hr />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
              <a
                href={`${this.props.linkCtaSummary}`}
                className="btn button--primary font-weight-bold financing-summary-cta"
              >
                Voltar para página inicial
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
