import { h, Component } from 'preact';
import PropTypes from 'prop-types';

export default class ItemCard extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    const {
      modelName,
      version,
      unit,
      thumb,
      thumbAlt,
      textPrice,
      price
    } = this.props;

    return(
      <div className="financing-simulador__card">
        <header className="financing-simulador__card__header">
          <h3 className="financing-simulador__card__title">{modelName}</h3>
          <h6 className="financing-simulador__card__subtitle">
            {version}
          </h6>
          <h6 className="financing-simulador__card__subtitle--gray">
            {unit && (
              <span>
                <i className="icon icon-map"></i>
                {unit}
              </span>
            )}
          </h6>
        </header>

        <div className="financing-simulador__card__content">
          <img 
            className="financing-simulador__card__img img-fluid"
            src={thumb} 
            alt={thumbAlt}
          />

          <div className="financing-simulador__card__price">
            <h5>
              {textPrice}:
              <span class="pl-2">R$ {price}</span>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

ItemCard.defaultProps = {
  modelName: '',
  version: '',
  unit: '',
  thumb: '',
  thumbAlt: '',
  textPrice: '',
  price: ''
}

ItemCard.propTypes = {
  modelName: PropTypes.string,
  version: PropTypes.string,
  unit: PropTypes.string,
  thumb: PropTypes.string,
  thumbAlt: PropTypes.string,
  textPrice: PropTypes.string,
  price: PropTypes.string
}
