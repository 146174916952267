/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Component } from 'preact';
import PropTypes from 'prop-types';
import IMask from 'imask';

import getUTM from '%/utils/utm';
import ItemCard from '../ItemCard';
import { priceMask } from '../../../utils/masks';
import FinancingOption from '../FinancingOption';
import FormMessageOverlay from '../../FormMessageOverlay';

import {
  setPersonalInformations,
  setModelInformations,
  setUsedModelValue,
  setUsedOnChange,
  setEntryValue,
  setFinancingValue,
  setFinancingSimulationData,
  setFinancingOptions,
} from '../../../stores/FinancingSimulator';

export default class FinancingSimulation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cpf: '',
      name: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      unit: '',
      model: '',
      version: '',
      modelYear: '',
      modelThumb: '',
      modelValue: '',
      usedModelValue: '',
      usedOnChange: '',
      entryValue: '',
      financingValue: null,
      financingOptions: [],
      financingMinEntryValue: null,
      financingEntryValueRecommended: null,
      financingOptionsError: null,
      financingOptionsErrorMessage: '',
      financingOptionsIsLoading: false,
      financingOptionsIsLoaded: false,
      showOverlay: false,
      entryValueIsValid: true,
      simulationFuelYear: '',
      simulationModelId: '',
      financingValueError: null,
      proposalId: null,
      installmentsId: null,
      commissionCode: null,
      selectedOption: null,
    };

    this.handleEntryValueChange = this.handleEntryValueChange.bind(this);
    this.simulateFinancing = this.simulateFinancing.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);

    window.store.FinancingSimulator.watch(setPersonalInformations, store => {
      this.setState({
        cpf: store.cpf,
        name: store.username,
        email: store.email,
        phone: store.phone,
        dateOfBirth: store.dateOfBirth,
      });
    });

    window.store.FinancingSimulator.watch(setModelInformations, store => {
      this.setState({
        unit: store.unit,
        model: store.model,
        version: store.version,
        modelYear: store.modelYear,
        modelThumb: store.modelThumb,
        modelValue: store.modelValue,
        simulationFuelYear: store.simulationFuelYear,
        simulationModelId: store.simulationModelId,
      });
    });

    window.store.FinancingSimulator.watch(setUsedModelValue, store => {
      this.setState({
        usedModelValue: store.usedModelValue,
      });

      this.setState(prevState => ({
        financingValue: this.calculateFinancingValue(
          this.entryValueMask.unmaskedValue,
          prevState.usedModelValue,
          prevState.modelValue,
        ),
      }));
    });

    window.store.FinancingSimulator.watch(setUsedOnChange, store => {
      this.setState({
        usedOnChange: store.usedOnChange,
      });
    });

    window.store.FinancingSimulator.watch(setFinancingOptions, store => {
      this.setState({
        installmentsId: store.installmentsId,
        commissionCode: store.commissionCode,
      });
    });
  }

  componentDidMount() {
    this.entryValueMask = IMask(this.entryValueInput, priceMask);
  }

  handleEntryValueChange(e) {
    const { value } = e.target;

    const entryValueFormatted = value.replace('R$ ', '');

    this.setState(prevState => ({
      entryValue: entryValueFormatted,
      entryValueIsValid: true,
      financingValue: this.calculateFinancingValue(
        this.entryValueMask.unmaskedValue,
        prevState.usedModelValue,
        prevState.modelValue,
      ),
    }));

    setEntryValue(entryValueFormatted);
  }

  handleOptionSelect(financingOption) {
    const updatedOptions = this.state.financingOptions.map(option => {
      return {
        ...option,
        isChecked: option.subOfferId === financingOption.installmentsId,
      };
    });

    this.setState({
      selectedOption: financingOption,
      financingOptions: updatedOptions,
    });
  }

  handleNextStep(e) {
    this.sendConversion();
    this.finishSimulation();

    this.setState({
      showOverlay: true,
    });

    e.stopImmediatePropagation();
  }

  handleCloseOverlay() {
    this.setState({
      showOverlay: false,
    });
  }

  handleValidation() {
    const formInputs = [
      {
        element: this.entryValueInput,
        stateKey: 'entryValueIsValid',
      },
    ];

    let formIsValid = true;

    formInputs.forEach(({ element, stateKey }) => {
      let { value } = element;
      value = value.trim();

      if (value === '' || value === 'R$') {
        this.setState({
          [stateKey]: false,
        });

        formIsValid = false;
      } else {
        this.setState({
          [stateKey]: true,
        });
      }
    });

    return formIsValid;
  }

  getConversionParams() {
    const {
      model: product,
      version,
      unit,
      modelValue,
      username: name,
      phone,
      cpf,
      email,
      dateOfBirth,
      mailing,
      phoning,
      whatsapping,
      usedOnChange,
      usedModelBrand,
      usedModelName,
      usedModelYear,
      usedModelKM,
      usedModelFipeCode,
      usedModelValue: usedModelSaleValue,
      entryValue,
      installments,
      installmentValue,
      financingValue,
      productId,
      financedValue,
      financingMinEntryValue,
    } = window.store.FinancingSimulator.getState();

    return {
      bait: '-7',
      paymentMethod: 'Quero financiar',
      SubOrigem__c: 'DDM Financiamento',
      category: this.props.category,
      utmz: getUTM(),
      brand: this.props.brand,
      channel: this.props.channel,
      unit,
      product_id: productId,
      product,
      version,
      modelValue,
      cpf,
      dateOfBirth,
      email,
      name,
      mailing,
      phone,
      phoning,
      whatsapping,
      usedOnChange,
      usedModelBrand,
      usedModelName,
      usedModelYear,
      usedModelKM,
      usedModelFipeCode,
      usedModelSaleValue,
      entryValue,
      installments,
      installmentValue,
      financingValue,
      financedValue: this.maskedValue(financedValue),
      financingMinEntryValue: this.maskedValue(financingMinEntryValue),
    };
  }

  async getFinancingSimulationData(proposalId) {
    if (!proposalId) return [];

    try {
      const { store_id } = this.props;

      const body = {
        proposalId,
        store_code: store_id,
      };

      const data = await window.service.checkFinancingSimulation(body);

      const { offers } = data;

      const hasOffers = offers && offers.length > 0;
      const hasSubOffers = hasOffers ? offers[0].subOffers : false;

      const financingOptions = hasSubOffers ? offers[0].subOffers : [];
      const financedValue = hasOffers ? offers[0].financedValue : 0;
      const financingMinEntryValue = hasOffers ? offers[0].minEntryValue : 0;
      const financingEntryValueRecommended = hasOffers
        ? offers[0].entryValueRecommended
        : 0;

      const errorDescription = data.errorDescription || '';

      setFinancingSimulationData({
        financedValue,
        financingMinEntryValue,
        financingEntryValueRecommended,
      });

      return {
        financingOptions,
        financedValue,
        financingMinEntryValue,
        financingEntryValueRecommended,
        errorDescription,
      };
    } catch (error) {
      throw new Error('Falha ao capturar as financing options');
    }
  }

  async findProposalId(simulationData) {
    if (!simulationData) return null;

    try {
      const data = await window.service.createFinancingSimulation(
        simulationData,
      );

      const proposalId = data.proposalId || null;

      this.setState({ proposalId });

      return proposalId;
    } catch (error) {
      throw new Error('Falha ao capturar o proposal ID');
    }
  }

  async checkSimulation(proposalId) {
    let attempts = 0;
    let intervalID;

    const maxAttempts = 60;
    const delay = 1000;

    return new Promise((resolve, reject) => {
      intervalID = setInterval(async () => {
        try {
          const {
            financingOptions,
            financingMinEntryValue,
            financingEntryValueRecommended,
            errorDescription,
          } = await this.getFinancingSimulationData(proposalId);

          if (errorDescription.length > 0) {
            clearInterval(intervalID);

            this.setState({
              financingOptionsError: true,
              financingOptionsErrorMessage: errorDescription,
              financingOptionsIsLoading: false,
              financingOptionsIsLoaded: false,
            });
          }

          if (financingOptions.length > 0) {
            clearInterval(intervalID);
            resolve({
              financingOptions,
              financingMinEntryValue,
              financingEntryValueRecommended,
            });
          }

          // eslint-disable-next-line no-plusplus
          attempts++;

          if (attempts >= maxAttempts) {
            clearInterval(intervalID);
            reject(
              new Error(
                'Limite máximo de tentativas atingido. Resposta desejada não foi recebida.',
              ),
            );
          }
        } catch (error) {
          clearInterval(intervalID);
          reject(error);
        }
      }, delay);
    });
  }

  async finishSimulation() {
    const { store_id } = this.props;
    const { proposalId, installmentsId, commissionCode } = this.state;

    try {
      const body = {
        proposalId,
        store_code: store_id,
        subOfferId: installmentsId,
        commissionCode,
      };

      await window.service.finishFinancingSimulation(body);
    } catch (error) {
      throw new Error('Falha ao finalizar a simulação de financiamento');
    }
  }

  sendConversion() {
    const params = this.getConversionParams();

    return window.serviceConversion
      .convert(params)
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  maskedValue(number) {
    return number.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  unmaskedValue(number) {
    const formatedNumber = Number(
      number.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    return formatedNumber;
  }

  calculateFinancingValue(entryValue, usedModelValue, modelValue) {
    const entryValueFormated = Number(entryValue);
    const usedModelValueFormated = this.unmaskedValue(usedModelValue);
    const modelValueFormated = this.unmaskedValue(modelValue);

    let financingValue =
      modelValueFormated - (entryValueFormated + usedModelValueFormated);
    financingValue = financingValue > 0 ? financingValue : 0;

    setFinancingValue(this.maskedValue(financingValue));

    return financingValue;
  }

  validateFinancingValue() {
    const { financingValue } = this.state;

    const financingValueIsValid = financingValue !== 0;

    this.setState({
      financingValueError: !financingValueIsValid,
      financingOptionsError: null,
    });

    return financingValueIsValid;
  }

  async simulateFinancing(e) {
    e.preventDefault();

    const { store_id } = this.props;
    const formIsValid = this.handleValidation();
    const financingValueIsValid = this.validateFinancingValue();

    if (formIsValid && financingValueIsValid) {
      const simulationData = {
        cpf: this.state.cpf,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        birth_date: this.state.dateOfBirth,
        st_vehicle_model: this.state.simulationModelId,
        st_vehicle_fuelYear: this.state.simulationFuelYear,
        st_manufacture_year: this.state.modelYear,
        st_vehicle_value: this.unmaskedValue(this.state.modelValue),
        store_code: store_id,
      };

      try {
        this.setState({
          financingOptionsError: null,
          financingOptionsIsLoading: true,
          financingOptionsIsLoaded: false,
        });

        const proposalId = await this.findProposalId(simulationData);
        const {
          financingOptions,
          financingMinEntryValue,
          financingEntryValueRecommended,
        } = await this.checkSimulation(proposalId);

        this.setState({
          financingOptions,
          financingMinEntryValue,
          financingEntryValueRecommended,
          financingOptionsIsLoading: false,
          financingOptionsIsLoaded: true,
        });
      } catch (error) {
        this.setState({
          financingOptionsError: true,
          financingOptionsIsLoading: false,
          financingOptionsIsLoaded: false,
        });

        console.error(error);
      }
    } else {
      this.setState({
        financingOptionsIsLoading: false,
        financingOptionsIsLoaded: false,
      });
    }
  }

  render() {
    const {
      model,
      version,
      unit,
      modelThumb,
      modelValue,
      usedOnChange,
      usedModelValue,
      entryValue,
      financingValue,
      financingOptions,
      financingMinEntryValue,
      financingEntryValueRecommended,
      financingOptionsError,
      financingOptionsErrorMessage,
      financingOptionsIsLoading,
      financingOptionsIsLoaded,
      showOverlay,
      entryValueIsValid,
      financingValueError,
    } = this.state;

    return (
      <div className="financing-simulation">
        <h2>Simulação de Financiamento</h2>
        <h6>
          Preencha os campos abaixo com seus dados para que possamos entrar em
          contato e prosseguir com a negociação.
        </h6>

        <hr />

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-3">
            <div className="financing-simulation__card">
              <ItemCard
                modelName={model}
                version={version}
                unit={unit}
                thumb={modelThumb}
                thumbAlt={model}
                textPrice="Valor do modelo"
                price={modelValue}
              />
            </div>
          </div>

          <hr className="d-md-none mx-3" />

          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mb-3">
            <div className="form-group">
              <label htmlFor="entry-value">Informe o valor de entrada:</label>
              <input
                onChange={this.handleEntryValueChange}
                value={entryValue ? `R$ ${entryValue}` : entryValue}
                type="text"
                className="form-control"
                name="entry-value"
                required
                placeholder="Ex: R$ 35.000"
                data-bouncer-target="#invalid-entry-value"
                ref={entryValueInput =>
                  (this.entryValueInput = entryValueInput)
                }
              />

              {!entryValueIsValid && (
                <div
                  id="invalid-entry-value"
                  className="invalid-feedback is-invalid-entry-value"
                >
                  <div className="error-message" id="bouncer-error_entry-value">
                    Por favor, informe esse campo
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="used-model-value">
                Valor do seminovo para negociação:
              </label>
              <input
                onChange={() => {}}
                value={`R$ ${usedModelValue || '0,00'}`}
                type="text"
                className="form-control"
                name="used-model-value"
                placeholder="Ex: R$ 50.000"
                data-bouncer-target="#invalid-used-model-value"
                readOnly
              />
            </div>

            <hr className="d-md-none mx-3" />

            <div className="form-group">
              <label htmlFor="financing-total-value">
                Valor total do financiamento:
              </label>
              <div className="financing-total-value-box">
                <h5>
                  R${' '}
                  {financingValue
                    ? this.maskedValue(financingValue)
                    : modelValue}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <FormMessageOverlay
          type="success"
          successMessage="Proposta enviada com sucesso!"
          handleClose={this.handleCloseOverlay}
          isVisible={showOverlay}
        />

        <div className="btns-group">
          <button
            type="button"
            className="btn btn-prev btn-prev--outline font-weight-bold"
            data-jump-to-personal-information={`${usedOnChange}`}
          >
            Anterior
          </button>
          <button
            type="button"
            className="btn button--primary font-weight-bold"
            onClick={this.simulateFinancing}
          >
            Simular financiamento
          </button>
        </div>

        {financingValueError && (
          <div className="financing-simulation__error-box">
            <hr />
            <div className="financing-simulation__error-box-content">
              <h5>
                Os valores de entrada informados superam o valor total do
                veículo. Por favor, informe valores válidos.
              </h5>
            </div>
          </div>
        )}

        {financingOptionsError && (
          <div className="financing-simulation__error-box">
            <hr />
            <div className="financing-simulation__error-box-content">
              <h5>
                {financingOptionsErrorMessage ||
                  'Tivemos um problema no momento e não foi possivel seguir com a análise de crédito. Por favor, tente novamente mais tarde.'}
              </h5>
            </div>
          </div>
        )}

        <div
          className={`financing-simulation__load-box ${
            financingOptionsIsLoading && 'is-loading'
          }`}
        >
          <hr />

          <div className="financing-simulation__load-box-content">
            <h5>
              Aguarde enquanto buscamos a melhor proposta de financiamento
            </h5>
            <div className="spinner" role="status" />
          </div>
        </div>

        <div
          className={`financing-simulation__financing-options-content ${
            financingOptionsIsLoaded && 'is-loaded'
          }`}
        >
          <div className="financing-simulation__info-box">
            <hr />
            <div className="financing-simulation__info-box-content">
              <div>
                <h5>
                  Entrada mínima
                  <span>
                    R${' '}
                    {financingMinEntryValue
                      ? this.maskedValue(financingMinEntryValue)
                      : '0,00'}
                  </span>
                </h5>
              </div>
              <div>
                <h5>
                  Entrada sugerida
                  <span>
                    R${' '}
                    {financingEntryValueRecommended
                      ? this.maskedValue(financingEntryValueRecommended)
                      : '0,00'}
                  </span>
                </h5>
              </div>
            </div>
          </div>

          <hr />

          <h4>Selecione a melhores opções de financiamento pra você:</h4>

          <div className="financing-simulation__financing-options">
            {financingOptions &&
              financingOptions.map((options, index) => (
                <FinancingOption
                  key={options.subOfferId}
                  id={options.subOfferId}
                  commissionCode={options.defaultCommissionCode}
                  installments={options.term}
                  installmentValue={options.installmentsValue}
                  chekedInInitialization={index === 0}
                  onSelect={financingOption =>
                    this.handleOptionSelect(financingOption)
                  }
                  isChecked={options.isChecked}
                />
              ))}
          </div>

          <hr />

          <div className="btns-group">
            <button
              type="button"
              className="btn button--disabled font-weight-bold"
            >
              <i className="icon icon-security" />
              <span>Fique tranquilo! Essa simulação é sem compromisso.</span>
            </button>
            <button
              type="button"
              className="btn button--primary font-weight-bold"
              onClick={this.handleNextStep}
            >
              Enviar proposta
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FinancingSimulation.defaultProps = {
  category: '',
  brand: '',
  channel: '',
  store_id: '',
};

FinancingSimulation.propTypes = {
  category: PropTypes.string,
  brand: PropTypes.string,
  channel: PropTypes.string,
  store_id: PropTypes.string,
};
